// MainPage/MainPage.js
import React from 'react';
import './mainpage.css'; // Update the filename here
import logo from '../../assets/codehidelogo1.png';

function MainPage() {
  return (
    <div className="main-page">
      <h1 className="heading">Welcome to Sagwan Wood: Your Premier Destination for Quality Furniture and Wood Products</h1>
      <div className="content">
        <p>Discover a world of craftsmanship and elegance at Sagwan Wood, your trusted source for premium furniture and wood products.</p>
        <p>From timeless classics to modern marvels, we offer a diverse selection of furniture and wood products to enhance your living space and elevate your lifestyle.</p>
        <div className="info">
          <h2>About Us</h2>
          <p>At Sagwan Wood, we're committed to excellence in every aspect of our business. Here's what sets us apart:</p>
          <ul>
            <li>Quality Craftsmanship: Our furniture is meticulously crafted by skilled artisans using the finest materials, ensuring durability, beauty, and functionality.</li>
            <li>Wide Selection: Whether you're furnishing your home, office, or outdoor space, we have a wide range of products to suit every style and preference.</li>
            <li>Customization Options: Personalize your furniture with our customization services, allowing you to create pieces that reflect your unique taste and lifestyle.</li>
            <li>Exceptional Service: From browsing our catalog to delivery and installation, our dedicated team is here to provide you with a seamless and enjoyable shopping experience.</li>
          </ul>
          <p>Experience the difference with Sagwan Wood. Browse our collection today and discover the perfect pieces to transform your space into a sanctuary of style and comfort.</p>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
