// Header.js
import React from 'react';
import BTlogoHeader from '../../assets/codehidelogo1.png';
import './header.css';
 
function Header() {
 
  return (
    <div>
      <header className="header">
        <a href="/" className="logo-bidtype">
          <img src={BTlogoHeader} alt="Logo" className="logo-bidtype1" />
        </a>

        <div className="three-name-header">
          <a href="/" className="Home-1">
            <div className="Buy-2">Welcome To SagwanWood.com</div>
          </a>
        </div>
 
      </header>
    </div>
  );
}

export default Header;
